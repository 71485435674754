import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { AppContext } from './AppContext';


const VariablesData = (props) => {
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const { saveVariablesData } = useContext(AppContext);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
      
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setData(result)
          saveVariablesData(result)
          props.onVariableCallback(result)
        },

        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else if (data) {
    return <div></div>
  }
  return <div />

  /*const fetchData = async () => {
    // Fetch data from your REST API
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };

  const { data, error, isLoading } = useQuery('varibleData', fetchData);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data){
    return props.onVariableCallback(data);
  }
  return <div />*/
}

export default VariablesData
