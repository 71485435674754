import { useState, useCallback } from 'react'
import { Button, Space } from 'antd'
import { useCurrentPng } from 'recharts-to-png'
import FileSaver from 'file-saver'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts'

function Chart(props) {
  var receivedTSData = false
  let multipleLineSelected = false
  const [getAreaPng, { ref: areaRef }] = useCurrentPng()
  const [chart, setChart] = useState('')
  let timeseriesData

  let result = []
  const colors = [
    '#8884d8',
    '#FFA500',
    '#0000FF',
    '#964B00',
    '#000000',
    '#258025',
  ]

  const handleExport = (data, variable) => {
    var blob = new Blob([JSON.stringify(data)], {
      type: 'text/plain;charset=utf-8',
    })
    FileSaver.saveAs(blob, `${variable}.json`)
  }
  const handleAreaDownload = useCallback(
    async (variable) => {
      const png = await getAreaPng()
      if (png) {
        FileSaver.saveAs(png, `${variable}.png`)
      }
    },
    [getAreaPng],
  )

  if (
    props.timeseries !== undefined &&
    props.timeseries !== '' &&
    props.timeseries.length > 0
  ) {
    receivedTSData = true

    if (Array.isArray(props.timeseries))
      timeseriesData = { ...props.timeseries[0] }
    else timeseriesData = { ...props.timeseries }

    if (props.timeseries.length > 1) {
      multipleLineSelected = true
      timeseriesData = props.timeseries
      timeseriesData.map((d) =>
        d.points.forEach(
          (value, i, a) =>
            (a[i].timestamp = new Date(value.timestamp).toLocaleDateString()),
        ),
      )

      // Get all unique timestamps from the array of objects
      const timestamps = Array.from(
        new Set(
          timeseriesData.flatMap((obj) =>
            Object.values(obj.points).map((p) => p.timestamp),
          ),
        ),
      )

      // For each timestamp, create a new object with timeseries data
      timestamps.forEach((timestamp) => {
        const obj = { timestamp }
        timeseriesData.forEach((d) => {
          const value = Object.values(d.points).find(
            (p) => p.timestamp === timestamp,
          )?.value
          obj[d.project_name] = value
        })
        result.push(obj)
      })
    } else {
      timeseriesData.points.forEach(
        (value, i, a) =>
          (a[i].timestamp = new Date(value.timestamp).toLocaleDateString()),
      )
      result = timeseriesData.points
    }
  }

  return (
    <>
      {receivedTSData && (
        <>
          <h4 style={{ marginLeft: '250px' }}>{props.variable}</h4>
          <div className="area-chart">
            <LineChart
              width={props.width}
              height={props.height}
              ref={areaRef}
              data={result}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="timestamp" />
              <YAxis
                label={{
                  value: `${props.unit}`,
                  offset: 10,
                  position: 'insideTop',
                }}
              ></YAxis>
              <Tooltip />
              <Legend />
              {multipleLineSelected ? (
                timeseriesData.map((d, index) => (
                  <Line
                    name={d.project_name}
                    type="monotone"
                    dataKey={d.project_name}
                    stroke={colors[index]}
                    dot={false}
                  />
                ))
              ) : (
                <Line
                  name={props.variable}
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  dot={false}
                />
              )}
            </LineChart>
            <br />
            <Space direction="vertical" style={{ marginLeft: '70px', marginBottom: '20px' }}>
              <Button
                type="primary"
                onClick={() => handleAreaDownload(props.variable)}
              >
                Download {props.variable} Chart
              </Button>
              <Button
                type="primary"
                onClick={() => handleExport(result, props.variable)}
              >
                Export {props.variable} Data
              </Button>
            </Space>
          </div>
        </>
      )}
    </>
  )
}
export default Chart
