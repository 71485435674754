export const PLANTS_DATA = 'plantsData';
export const STEADY_STATE_DISPATCH_PLANTS = 'steadyStateDispatchPlants';
export const STEADY_STATE_DISPATCH_PLANTS_DATA = 'steadyStateDispatchPlantsData';
export const LOAD_MODEL_BUTTON = 'loadModelButtonClicked';
export const MODEL_DATA = 'modelData';
export const SLIDER_VALUE = 'sliderValue';
export const MARKER_CLICKED = 'markerClicked';
export const MARKER = 'marker';
export const SELECTED_PLANTS = 'selectedPlants';
export const VARIABLES = 'variables';
export const SELECTED_VARIABLES = 'selectedVariables';
export const TIMESERIES_PLOT_SUBMITTED = 'timeseriesPlotSubmitted';
export const SELECTED_DATES = 'selectedDates';
export const YEAR = 'year';
export const SEASON = 'season';
export const STEADY_STATE = 'steady_state'
export const DISPATCH_ALL = 'dispatch_all'