import { useState, useEffect } from 'react'
import { Row, Col, Button, Checkbox } from 'antd'
const UnitLevelDispatch = (props) => {
  const [data, setData] = useState('')
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const body = props.parameters

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
      const data = await response.json()
      props.onDispatch(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  return (
    <>
      <Row style={{ paddingTop: '30px', paddingBottom: '20px' }}>
        <Col>
          <Button type="primary" onClick={fetchData}>
            Conduct Group Dispatch
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default UnitLevelDispatch
