import { useState, useEffect } from 'react'
import { useQuery } from 'react-query';

const Projects = (props) => {
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setData(result)
          props.parentCallback(result)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
  }, [])

  if (error) {
    return <div></div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else if (data) {
    return <div></div>
  }

}

export default Projects
