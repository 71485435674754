// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-layout {
    padding: 0 48px;
    background-color: #d8ebfa;
    overflow: auto
  }
  
.documentation-content-div {
  text-align: left;
  margin: 16px 0;
  padding: 24;
  padding-top: 0;
  min-height: calc(100vh - 70px);
  background: colorBgContainer;
  background-color: #d8ebfa;
  overflow: auto;
  font-size: 20px;
}
.documentation-tabs {
    font-size: 30px;
}
  
.ant-tabs-tab {
    font-size: 30px;
  }
.tab-content {
  background-color: #d8ebfa;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/HydrowireGUI/HydatHome.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB;EACF;;AAEF;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW;EACX,cAAc;EACd,8BAA8B;EAC9B,4BAA4B;EAC5B,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;EACjB;AACF;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".page-layout {\n    padding: 0 48px;\n    background-color: #d8ebfa;\n    overflow: auto\n  }\n  \n.documentation-content-div {\n  text-align: left;\n  margin: 16px 0;\n  padding: 24;\n  padding-top: 0;\n  min-height: calc(100vh - 70px);\n  background: colorBgContainer;\n  background-color: #d8ebfa;\n  overflow: auto;\n  font-size: 20px;\n}\n.documentation-tabs {\n    font-size: 30px;\n}\n  \n.ant-tabs-tab {\n    font-size: 30px;\n  }\n.tab-content {\n  background-color: #d8ebfa;\n  font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
