import React from 'react';


const Message = () => {

  return <>
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
  <h2 style={{ textAlign: 'center'}}>Please login to access this content</h2>
  </div>

  </>
}

export default Message
