// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ol-map {
    min-width: 100px;
    min-height: 100px;
    height: 250px;
    width: "100%";
    @media screen and (min-width: 1080px) {
        height: 350px;
    }
  

}

.ol-control {
    position: absolute;
    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}`, "",{"version":3,"sources":["webpack://./src/components/maps/Map.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb;QACI,aAAa;IACjB;;;AAGJ;;AAEA;IACI,kBAAkB;IAClB,uCAAuC;IACvC,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,WAAW;AACf","sourcesContent":[".ol-map {\n    min-width: 100px;\n    min-height: 100px;\n    height: 250px;\n    width: \"100%\";\n    @media screen and (min-width: 1080px) {\n        height: 350px;\n    }\n  \n\n}\n\n.ol-control {\n    position: absolute;\n    background-color: rgba(255,255,255,0.4);\n    border-radius: 4px;\n    padding: 2px;\n}\n\n.ol-full-screen {\n    top: .5em;\n    right: .5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
