import React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query';
import HydrowireGUI from './components/HydrowireGUI/HydrowireGUI';
import TimeSeriesTab from './components/TimeSeriesTab';
import SteadyStateDispatchTab from './components/SteadyStateDispatchTab';
import { StateProvider } from './components/AppContext'
import ProtectedRoute from './components/ProtectedRoute';
import { ConfigProvider } from "antd";
import HydatHome from './components/HydrowireGUI/HydatHome';
const queryClient = new QueryClient();

function App() {
  return (
    <StateProvider>
      <QueryClientProvider client={queryClient}>
      <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#3b7ca3',
        },
        components: {
          Button:{
            primaryShadow: 'none'

          },
          Tabs: {
            titleFontSizeLG: '20px'
          },
          Descriptions : {
            labelBg: '#d8ebfa'
          }
        }}} >
        <BrowserRouter>
          <Routes>

                <Route path="/" element={<HydrowireGUI />} >
                <Route index element={<Navigate to="/home" />} />
                <Route path="/home" element={<ProtectedRoute element={<HydatHome />} />} />
                <Route path="/timeseries" element={<ProtectedRoute element={<TimeSeriesTab />} />} />
                <Route path="/steadystatedispatch" element={<ProtectedRoute element={<SteadyStateDispatchTab />} />} />
                </Route>
                <Route path="/callback" element={<HydrowireGUI  />} />
                <Route path="*" element={<Navigate to="/home" />} />

          </Routes>
        </BrowserRouter>
       </ConfigProvider>
      </QueryClientProvider>
    </StateProvider>
  );
}

export default App;