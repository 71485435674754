import { useContext, useEffect } from 'react'
import MapContext from './MapContext'
import VectorLayer from 'ol/layer/Vector'

import VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import { Icon, Style } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import markerIcon from './map-marker-svgrepo-com.png'
const ProjectVectorLayer = ({
  marker,
  markerId,
  plants,
  style,
  zIndex = 0,
  onFeatureClick,
}) => {
  const { map } = useContext(MapContext)

  useEffect(() => {
    if (!map) return

    const markerFeature = new Feature({
      geometry: new Point(fromLonLat(marker)),
      id: markerId,
    })

    const markerLayer = new VectorLayer({
      source: new VectorSource({
        features: [markerFeature],
      }),
      style: new Style({
        image: new Icon({
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: markerIcon
        }),
      }),
      // Make the layer interactive
      interactive: true,
    })

    map.addLayer(markerLayer)
    markerLayer.setZIndex(zIndex)

    map.on('click', (event) => {
      const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => {
        return feature
      })
      if (feature) {
        onFeatureClick(feature)
      }
    })

    return () => {
      if (map) {
        map.removeLayer(markerLayer)
      }
    }
  }, [map])

  return null
}

export default ProjectVectorLayer
