// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-column {
   /* position: fixed;
    top: 0;
    left: 0;
    width: 41.67%;
    height: 100vh; 
 
    padding: 10px;
    box-sizing: border-box;*/

    position: 'fixed';
    top: 80px;
    margin-left: 25px;
    margin-right: 25px;
    bottom: 0;

    min-width: '600px';
    width: '600px';
    min-height: calc(100vh - 70px);
    z-index: 1;
  }
  
  .responsive-column {
   
    
    margin-left: '700px';

  }

  @media screen and(max-width: 992px) {
    .fixed-column, .responsive-column {
      position: static;
      width: 100%;
      

      height: auto;
    }
  }

  @media (max-width: 992px) {
    .fixed-column {
      min-height: auto;
    }
  }

`, "",{"version":3,"sources":["webpack://./src/components/TimeSeriesTab.css"],"names":[],"mappings":"AAAA;GACG;;;;;;;4BAOyB;;IAExB,iBAAiB;IACjB,SAAS;IACT,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;;IAET,kBAAkB;IAClB,cAAc;IACd,8BAA8B;IAC9B,UAAU;EACZ;;EAEA;;;IAGE,oBAAoB;;EAEtB;;EAEA;IACE;MACE,gBAAgB;MAChB,WAAW;;;MAGX,YAAY;IACd;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".fixed-column {\n   /* position: fixed;\n    top: 0;\n    left: 0;\n    width: 41.67%;\n    height: 100vh; \n \n    padding: 10px;\n    box-sizing: border-box;*/\n\n    position: 'fixed';\n    top: 80px;\n    margin-left: 25px;\n    margin-right: 25px;\n    bottom: 0;\n\n    min-width: '600px';\n    width: '600px';\n    min-height: calc(100vh - 70px);\n    z-index: 1;\n  }\n  \n  .responsive-column {\n   \n    \n    margin-left: '700px';\n\n  }\n\n  @media screen and(max-width: 992px) {\n    .fixed-column, .responsive-column {\n      position: static;\n      width: 100%;\n      \n\n      height: auto;\n    }\n  }\n\n  @media (max-width: 992px) {\n    .fixed-column {\n      min-height: auto;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
