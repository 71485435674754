import React, { useState, useEffect } from 'react'

import { Layout, Tabs, Row, Col, Image, Modal, Button, Grid } from 'antd'
import timeseries from '../../assets/timeseries.png'

import steadystatedispatch from '../../assets/steadystatedispatch.png'

import userGuide from '../../assets/user-guide.pdf'

import './HydatHome.css'
const { useBreakpoint } = Grid;

const HydatHome = (props) => {
  const screens = useBreakpoint();
  const [selectedTab, setSelectedTab] = useState('1')
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('isFirstVisitHomePage')
    if (!isFirstVisit) {
      setIsModalVisible(true)
      localStorage.setItem('isFirstVisitHomePage', 'false')
    }
  }, [])

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div
      className="page-layout"
      /*style={{

       // position: 'fixed',

        //backgroundColor: '#DAD3BE',
        minWidth: '600px',
        //minHeight: '100vh',
        zIndex: 1,
        left: '10px',
      }}*/
    >
      <h2 style={{ textAlign: 'center' }}>
        Hy-DAT (Hydrological Dispatch and Analysis Tool){' '}
      </h2>
      <div>
        <Modal
          title="Disclaimer"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
        >
          <></>{' '}
          <p>
            This material was prepared as an account of work sponsored by an
            agency of the United States Government. Neither the United States
            Government, the United States Department of Energy, the Contractor,
            any of their employees, nor any jurisdiction or organization
            involved in the development of these materials, makes any warranty,
            express or implied, or assumes any legal liability or responsibility
            for the accuracy, completeness, or usefulness of any information,
            apparatus, product, software, or process disclosed, or represents
            that its use would not infringe privately owned rights.
          </p>
          <p>
            References to any specific commercial product, process, or service
            by trade name, trademark, manufacturer, or otherwise do not
            necessarily constitute or imply its endorsement, recommendation, or
            favoring by the United States Government or any agency thereof, or
            by Battelle Memorial Institute. The views and opinions of authors
            expressed herein do not necessarily state or reflect those of the
            United States Government or any agency thereof.
          </p>
          <div style={{ textAlign: 'center' }}>
            <p style={{ margin: 0, padding: 0 }}>
              PACIFIC NORTHWEST NATIONAL LABORATORY
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              <i>operated by</i>
            </p>
            <p style={{ margin: 0, padding: 0 }}>BATTELLE</p>
            <p style={{ margin: 0, padding: 0 }}>
              <i>for the</i>
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              UNITED STATES DEPARTMENT OF ENERGY
            </p>
            <p style={{ margin: 0, padding: 0 }}>
              <i>under Contract DE-AC05-76RL01830</i>
            </p>
          </div>
        </Modal>
        <p>
          Hy-DAT is first-of-a-kind tool that provides a more accurate
          representation of hydropower generation in the Western Interconnection
          grid under a wide range of hydrological conditions
        </p>
        <p>
          Hy-DAT combines historical hydrologic and energy generation data from
          the Energy Management System to represent hydropower in the Western
          Interconnection grid under different hydrologic conditions, combining
          information on both energy generation and environmental constraints.
          It can create accurate profiles for individual water basins during
          different seasons and conditions, and provide information about the
          interdependent relationships between cascading hydropower plants in
          the same basin.
        </p>
        <p>
          Hy-DAT can be used to generate more accurate insights during power
          system planning and operational studies. These studies form the basis
          of many critical grid-related decisions—from everyday operations that
          help meet energy demand to investments in new energy infrastructure
          for the future. Most hydropower generation models currently used in
          large-scale studies do not incorporate data on hydrological
          conditions—a major limitation considering the growing impacts of
          climate change on hydropower resources. Using Hy-DAT to better
          simulate real-world scenarios supports more informed decisions to
          ensure future hydropower generation will meet grid needs.
        </p>

        <div className="documentation-content-div">
          <p>
            Click on any of the tabs below, to learn more about the functions of
            the Hydrowire Tool{' '}
          </p>
          <Tabs defaultActiveKey="timeseries"  type="card" size='large' className="documentation-tabs"  onFocus={(e) => (e.target.style.outline = 'none')}>
            <Tabs tab="Time Series" key="timeseries-tab">
              <Layout className="tab-content">

                <div style={{display: 'flex', flexWrap: screens.lg?'nowrap': 'wrap'}}>
                <div>
                    <h3>On the TimeSeries page:</h3>
                    <ol>
                      <li>
                        Upon user login, the dataset should connect
                        automatically.{' '}
                      </li>
                      <ul>
                        <li>If not, connect to “./app/data/database.db”</li>
                      </ul>
                      <li>
                        User can select multiple plants for visualization
                        <ul>
                          <li>
                            Selected plant locations will be displayed on the
                            map.
                          </li>
                          <li>
                            Clicking on a specific plant on the map will display
                            its details (e.g., nominal head, total units, etc.)
                            in the table.
                          </li>
                        </ul>
                      </li>
                      <li>
                        User can select a specific generator unit from the
                        dropdown menu in the “Power Plant” table.
                        <ul>
                          <li>
                            The selected generator’s details (e.g., bus name,
                            bus number, nominal pmax, etc.) will be shown in a
                            pop-out window, along with efficiency curves at
                            different head ranges (if available).
                          </li>
                        </ul>
                      </li>
                      <li>
                        User can select hydropower variables to visualize for a
                        specific period.
                        <ul>
                          <li>
                            Only available variables for the selected plants
                            will be shown in the dropdown menu.
                          </li>
                          <li>
                            Users can only select periods that are available for
                            the selected variables and plants, possibly needing
                            to go back to 2021.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Selected variables are visualized for the given period.
                        User can export the chart and corresponding data.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <Image
                      width={screens.lg? 800 : 600}
                      src={timeseries}
                      alt="Timeseries example"
                    />
                 </div>
                </div>
              </Layout>
            </Tabs>
            <Tabs tab="Steady State Dispatch" key="steady-state-dispatch-tab">
              <Layout className="tab-content">
              <div style={{display: 'flex', flexWrap: screens.lg?'nowrap': 'wrap'}}>
              <div>
                    <h3>On the Steady State Dispatch page:</h3>
                    <ol>
                      <li>
                        Users select hydro plants to be dispatched while
                        specifying operation season and general dispatch
                        threshold.
                        <ul>
                          <li>
                            Clicking the “Load Dataset and Model” creates
                            individual subtabs for each plant.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users specify water head for each hydro plant in each
                        subtab
                        <ul>
                          <li>
                            Reference range and default value are provided on
                            the page.
                          </li>
                          <li>
                            Clicking the “Next Step” button will calibrate new
                            pmax and pgen.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users can specify the local dispatch threshold to modify
                        pgen and provide additional hydropower parameters
                        inputs.
                      </li>
                      <li>
                        The “Check Interdependency” option is available when the
                        current plant’s upstream plant is also selected.
                        <ul>
                          <li>
                            Clicking the “Check Upstream” button will verify if
                            the upstream plan has been dispatched. If yes, it
                            will load user-provided head and power instead of
                            default values.
                          </li>
                          <li>
                            Clicking the “Check Interdependency” button will
                            re-calibrate pmax and pgen.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users can conduct group-level dispatch by clicking the
                        “Conduct Group Dispatch” button.
                        <ul>
                          <li>
                            Generator units are grouped according to their pmax.
                          </li>
                          <li>
                            If the “Check with Efficiency Curves (if available)”
                            option is ticked, the dispatch results will be
                            verified to ensure the efficiency thresholds are
                            satisfied for most generator units.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Users can conduct unit-level dispatch by clicking the
                        “Conduct Unit Dispatch” button.
                        <ul>
                          <li>
                            The “Power of Active” in each group will be randomly
                            allocated to “Number of Active” units.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Unit-level dispatch results can be exported for further
                        analysis, as shown on the right.
                      </li>
                      <li>
                        Additional options on the left panel:
                        <ul>
                          <li>
                            “Dispatch All Selected Plants” is a one click button
                            that performs all the steps - it loads all selected
                            plants, checks interdependency, and conducts
                            dispatches.
                          </li>
                          <li>
                            “Export All Dispatches” combines the latest dispatch
                            results of all selected plants into one .csv file.
                          </li>
                          <li>
                            “Reset” resets the “Steady State Dispatch” tab.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                  <div>
                    <Image
                      width={screens.lg? 800 : 600}
                      src={steadystatedispatch}
                      alt="Steady state dispatch example"
                    />
</div>
</div>
              </Layout>
            </Tabs>
            <Tabs tab="User Guide" key="user-guide">
              <Layout className="tab-content">
                <embed src={userGuide} width="auto" height="900px" />
              </Layout>
            </Tabs>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default HydatHome
