import { Row, Col, Tooltip } from 'antd'
import Plants from './Plants'
import Variables from './Variables'
import PowerplantTable from './PowerplantTable'
import React, { useState, useEffect } from 'react'

import MapOpenLayer from './maps/MapOpenLayer'
import {
  MARKER_CLICKED,
  MARKER,
  PLANTS_DATA,
  SELECTED_PLANTS,
} from '../constants'
import './TimeSeriesTab.css'
function TimeSeriesTab() {
  const markerClickedStorage = localStorage.getItem(MARKER_CLICKED)
  const [markerClicked, setMarkerClicked] = useState(
    markerClickedStorage ? JSON.parse(markerClickedStorage) : false,
  )

  const markerStorage = localStorage.getItem(MARKER)

  const [userClickedMarker, setUserClickedMarker] = useState(
    markerStorage ? JSON.parse(markerStorage) : '',
  )

  let coordinatesArray = []
  let userSelectedPlants
  let plantCodes = []
  let plantsSelected = false

  const plantsDataStorage = localStorage.getItem(PLANTS_DATA)

  const [plantsData, setPlantsData] = useState(
    plantsDataStorage ? JSON.parse(plantsDataStorage) : [],
  )

  const selectedPlantsStorage = localStorage.getItem(SELECTED_PLANTS)
  const plant_storage = selectedPlantsStorage
    ? JSON.parse(selectedPlantsStorage)
    : []
  const [selectedPlants, setSelectedPlants] = useState(
    selectedPlantsStorage ? JSON.parse(selectedPlantsStorage) : [],
  )

  if (
    plantsData !== undefined &&
    plantsData.length > 0 &&
    selectedPlants.length > 0
  ) {
    userSelectedPlants = selectedPlants.map((splant) =>
      Object.values(plantsData).filter((value) => value.plant_name === splant),
    )

    if (userSelectedPlants.length > 1) {
      plantCodes = userSelectedPlants.map((p) => p[0].project_name)
    } else {
      plantCodes = userSelectedPlants[0][0].project_name
    }
    coordinatesArray = [
      userSelectedPlants[0][0].longitude,
      userSelectedPlants[0][0].latitude,
    ]

    plantsSelected = true
  } else plantsSelected = false
  useEffect(() => {
    if (plantsData !== undefined && plantsData.length > 0) {
      localStorage.removeItem(PLANTS_DATA)
      localStorage.setItem(PLANTS_DATA, JSON.stringify(plantsData))
    }
    if (selectedPlants !== undefined && selectedPlants.length > 0) {
      localStorage.removeItem(SELECTED_PLANTS)
      localStorage.setItem(SELECTED_PLANTS, JSON.stringify(selectedPlants))
    }
  }, [selectedPlants, plantsData])

  const getPlants = (plants, projectData) => {
    setSelectedPlants(plants)
    setPlantsData(projectData)
  }
  const handleMarkerClick = (feature) => {
    setMarkerClicked(true)
    setUserClickedMarker(feature.values_.id)
    localStorage.removeItem(MARKER_CLICKED)
    localStorage.setItem(MARKER_CLICKED, JSON.stringify(true))
    localStorage.removeItem(MARKER)
    localStorage.setItem(MARKER, JSON.stringify(feature.values_.id))
  }

  return (
    <div>
      <h1 style={{ textAlign: 'left', marginLeft: '36px' }}>Timeseries</h1>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="fixed-column">
            <h4 style={{ padding: '0px', margin: '10px' }}>Select Plants</h4>
            <div style={{ marginTop: '20px' }}>
              <Plants
                selectedPlants={plant_storage}
                selectPlants={getPlants}
                tooltipMessage={
                  'Select multiple plants for visualization. Selected plants’ locations will be displayed on the map. Clicking on a specific plant on the map will display its details (e.g., nominal head, total units, etc.) in the table'
                }
              />
            </div>

            <MapOpenLayer
              coordinates={coordinatesArray}
              plants={userSelectedPlants}
              onMarkerClick={handleMarkerClick}
            />

            {plantsSelected &&
              markerClicked &&
              userSelectedPlants !== undefined && (
                <>
                  <PowerplantTable
                    plant={
                      userSelectedPlants.length > 1
                        ? userSelectedPlants?.find(
                            (u) => u[0].project_name === userClickedMarker,
                          )
                          ? userSelectedPlants?.find(
                              (u) => u[0].project_name === userClickedMarker,
                            )[0]
                          : ''
                        : userSelectedPlants[0][0]
                    }
                  />
                </>
              )}
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          //span={14}
          //offset={10}
        >
          <div className="responsive-column">
            <>
              <h4 style={{ paddingLeft: '15px', margin: '10px' }}>
                Select Variables and Date Range
              </h4>
              <Variables plantCodes={plantCodes} />
            </>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TimeSeriesTab
