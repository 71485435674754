import React, { useState } from 'react'
import { Tabs } from 'antd'
import ChildPlantTab from './ChildPlantTab'
import ChildPlantTabAll from './ChildPlantTabAll'
import { useEffect } from 'react'

const { TabPane } = Tabs
function SteadyStateDispatchDataTabs(props) {
  const [activeKey, setActiveKey] = useState('1')
  const [items, setItems] = useState([])

  useEffect(() => {
    if (props.selectedPlants.length === 0) {
     /* setItems(
        props.modelData
          .filter((p, i) => p.availability === true)
          .map((pr, i) => {
            return {
              label: pr.project,
              key: i,
              children: (
                <ChildPlantTabAll
                  tabKey={i}
                  season={props.season}
                  sliderValue={props.sliderValue}
                  modelData={props.modelData.find(
                    (d) => d.project === pr.project,
                  )}
                  allData={props.modelData.find(
                    (d) => d.project === pr.project,
                  )}
                />
              ),
            }
          }),
      )*/
    } else {
      setItems(
        props.dispatchAll
          ?/* props.selectedPlants
              .map((plant, i) => {
                return props.modelData[0].find(
                  (d) =>
                    d.project ===
                    props.plantsData.find((p) => p.plant_name === plant)
                      .project_name,
                )
              })*/
              props.modelData[0]
              .filter((p, i) => p.availability === true)
              .map((plant, i) => {
                // props.selectedPlants.map((plant, i) => {
                return {
                  label: props.plantsData.find(
                    (p) => p.project_name === plant.project,
                  ).plant_name,
                  key: i,
                  //find the correct plant details and pass it to render
                  children: (
                    <ChildPlantTabAll
                      tabKey={i}
                      season={props.season}
                      sliderValue={props.sliderValue}
                      /*modelData={props.modelData[0].find(
                (d) =>
                  d.project ===
                  props.plantsData.find((p) => p.plant_name === plant)
                    .project_name,
              )}*/
                      modelData={plant}
                      allData={props.modelData[1].find(
                        (pr) =>
                          pr.project ===
                          props.plantsData.find(
                            (p) => p.project_name === plant.project,
                          ).project_name,
                      )}
                    />
                  ),
                }
              }) //map,
          : /*props.selectedPlants
              .map((plant, i) => {
                return props.modelData.find(
                  (d) =>
                    d.project ===
                    props.plantsData.find((p) => p.plant_name === plant)
                      .project_name,
                )
              })*/
              props.modelData
              .filter((modelDatap, i) => modelDatap.availability === true)
              .map((plant, i) => {
                // props.selectedPlants.map((plant, i) => {
                return {
                  label: props.plantsData.find(
                    (p) => p.project_name === plant.project,
                  ).plant_name,
                  key: i,
                  //find the correct plant details and pass it to render
                  children: (
                    <ChildPlantTab
                      tabKey={i}
                      season={props.season}
                      sliderValue={props.sliderValue}
                      /* modelData={props.modelData.find(
                (d) =>
                  d.project ===
                  props.plantsData.find((p) => p.plant_name === plant)
                    .project_name,
              )}*/
                      modelData={plant}
                    />
                  ),
                }
              }),
      ) //setItems
    } //else
  }, [props.selectedPlants])

  const handleTabChange = (key) => {
    setActiveKey(key)
  }

  const handleTabClose = (targetKey) => {
    const newItems = items.filter((item) => item.key !== targetKey)
    setItems(newItems)
  }

  return (
    <>
      <Tabs
        hideAdd
        defaultActiveKey="1"
        onChange={handleTabChange}
        type="editable-card"
        onEdit={handleTabClose}
        items={items}
        tabPosition="top"
        onFocus={(e) => (e.target.style.outline = 'none')}
      />
    </>
  )
}
export default SteadyStateDispatchDataTabs
