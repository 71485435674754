import { useState, useEffect } from 'react'
import { Row, Button } from 'antd'

const DispatchExample = (props) => {
  const [data, setData] = useState('')
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const dispatchExample = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
      const data = await response.json()
      props.onDispatchExample(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  return (
    <Row>
      <Button type="primary" onClick={dispatchExample}>
        Conduct Unit Dispatch
      </Button>
    </Row>
  )
}

export default DispatchExample
