import { useState, useEffect } from 'react'
import Chart from './Chart'
import EfficiencyCurve  from './EfficienyCurve'

const EfficiencyCurveData = (props) => {
  const [data, setData] = useState(props.data)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)


  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/${props.path}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setData(result)
        },

        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
  }, [props.path])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading Efficiency Curve...</div>
  } else if (data) {
    if (data[0].length > 0) {
    return (
      <div>
        <EfficiencyCurve width={650} height={300} data={data} />
      </div>
    )}
    else if (data[0].length == 0) {
      return (
        <p>
          No Efficiency Curve Data
        </p>
      )
    }

  }
  return <div />
}

export default EfficiencyCurveData
