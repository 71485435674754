import { useState, useEffect } from 'react'
import {
  Row,
  Col,
  InputNumber,
  Button,
  Slider,
  Descriptions,
  Checkbox,
  Select,
  Space,
  Alert,
  Table,
  Grid
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import CalculatePowerOutput from './CalculatePowerOutput'
import InterdependencyCheck from './InterdependencyCheck'
import UnitLevelDispatch from './UnitLevelDispatch'
import DispatchExample from './DispatchExample'
import ExportFile from './ExportFile'
import CheckUpstream from './CheckUpstream'
import { STEADY_STATE } from '../constants'
import FileSaver from 'file-saver'

const { useBreakpoint } = Grid;

const items = [
  {
    label: 'Spring',
    value: 'Spring',
  },
  {
    label: 'Summer',
    value: 'Summer',
  },
  {
    label: 'Fall',
    value: 'Fall',
    disabled: true,
  },
  {
    label: 'Winter',
    value: 'Winter',
  },
]

function ChildPlantTab(props) {
  console.log(props.tabKey)
  const screens = useBreakpoint();
  const [powerOutput, setPowerOutput] = useState('')
  const dispatchHeadStorage = localStorage.getItem(
    `${STEADY_STATE}-dispatchHead-${props.tabKey}`,
  )
  const [dispatchHeadValue, setDispatchHeadValue] = useState(
    dispatchHeadStorage
      ? JSON.parse(dispatchHeadStorage)
      : props.modelData.model_input_parameters[0].referencevalue,
  )

  const dispatchThresholdStorage = localStorage.getItem(
    `${STEADY_STATE}-dispatchThreshold-${props.tabKey}`,
  )
  const [dispatchThreshold, setDispatchThreshold] = useState(
    dispatchThresholdStorage
      ? JSON.parse(dispatchThresholdStorage)
      : props.sliderValue * 100,
  )

  const headValueStorage = localStorage.getItem(
    `${STEADY_STATE}-headValue-${props.tabKey}`,
  )
  const [headValue, setHeadValue] = useState(
    headValueStorage ? JSON.parse(headValueStorage) : null,
  )
  const powerValueStorage = localStorage.getItem(
    `${STEADY_STATE}-powerValue-${props.tabKey}`,
  )
  const [powerValue, setPowerValue] = useState(
    powerValueStorage ? JSON.parse(powerValueStorage) : null,
  )
  const disptachDataStorage = localStorage.getItem(
    `${STEADY_STATE}-dispatchData-${props.tabKey}`,
  )
  const [dispatchData, setDispatchData] = useState(
    disptachDataStorage ? JSON.parse(disptachDataStorage) : '',
  )
  const unitDisptachDataStorage = localStorage.getItem(
    `${STEADY_STATE}-unitDispatchData-${props.tabKey}`,
  )
  const [dispatchExampleData, setDispatchExampleData] = useState(
    unitDisptachDataStorage ? JSON.parse(unitDisptachDataStorage) : '',
  )

  const [
    interdependencyButtonChecked,
    setInterdependencyButtonChecked,
  ] = useState(false)
  const [unitLevelDispatchClicked, setUnitLevelDispatchClicked] = useState(
    false,
  )
  const [dispatchExampleClicked, setDispatchExampleClicked] = useState(false)
  const [exportFileClicked, setExportFileClicked] = useState(false)

  const [efficiencyCurveChecked, setEfficiencyCurveChecked] = useState(true)

  const [exportFileMessage, setExportFileMessage] = useState('')
  const nextStepStorage = localStorage.getItem(
    `${STEADY_STATE}-nextStep-${props.tabKey}`,
  )
  const [nextStep, setNextStep] = useState(
    nextStepStorage ? JSON.parse(nextStepStorage) : false,
  )

  const [disabled, setDisabled] = useState(props.modelData.interdependency)
  const [interdependencyChecked, setInterdependencyChecked] = useState(disabled)
  const [selectedUnit, setSelectedUnit] = useState()
  const [selectedUnitArray, setSelectedUnitArray] = useState([])
  const moreInputValueStorage = localStorage.getItem(
    `${STEADY_STATE}-moreInputValue-${props.tabKey}`,
  )
  const [moreInputValue, setMoreInputValue] = useState(
    moreInputValueStorage ? JSON.parse(moreInputValueStorage) : null,
  )
  const seasonStorage = localStorage.getItem(
    `${STEADY_STATE}-season-${props.tabKey}`,
  )
  const [season, setSeason] = useState(
    seasonStorage ? JSON.parse(seasonStorage) : props.season,
  )
  const [interdependencyMessage, setInterdependencyMessage] = useState()
  const [checkUpstreamMessage, setCheckUpstreamMessage] = useState()
  const [sliderValue, setSliderValue] = useState(
    Number(powerOutput.pmax1 * powerOutput.dispatch_threshold),
  )
  const [marks, setMarks] = useState({})
  const maxValue = powerOutput.pmax1
  let inputNames = []
  let inputValues = []
  const moreParameters = {
    other_input_names: inputNames,
    other_input_values: inputValues,
  }

  const startIndex = 1
  const unitColumns = [
    {
      title: 'Unit ID',
      dataIndex: 'unit_ID',
      key: 'unit_ID',
    },
    {
      title: 'Pmax [MW]',
      dataIndex: 'pmax',
      key: 'pmax',
    },
    {
      title: 'Dispatch Status',
      dataIndex: 'dispatch_status',
      key: 'dispatch_status',
    },
    {
      title: 'Dispatch Power',
      dataIndex: 'dispatch_power',
      key: 'dispatch_power',
      render: (dispatch_power) => {
        return dispatch_power.toFixed(2)
      },
    },
    {
      title: 'Existing case: Status',
      dataIndex: 'previous_status',
      key: 'previous_status',
      responsive: ['lg']
    },
    {
      title: 'Existing case: Power',
      dataIndex: 'previous_power',
      key: 'previous_power',
      responsive: ['lg']
    },
  ]
  const columns = [
    {
      title: 'Group',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Available Units',
      dataIndex: 'group_units',
      key: 'group_units',
      responsive: ['lg'],
      render: (group_units) => {
        return (
          <Select
            style={{ width: '200px', height: '32px', overflowY: 'scroll' }}
            allowClear
            value={setSelectedUnit(group_units[0])}
            defaultValue={group_units[0]}
            onChange={handleChange}
            options={group_units.map((unit) => ({ label: unit, value: unit }))}
          />
        )
      },
    },
    {
      title: 'Pmax',
      dataIndex: 'pmax',
      key: 'pmax',
      render: (pmax) => {
        return pmax.toFixed(2)
      },
    },
    {
      title: 'Number of Active',
      dataIndex: 'number_active',
      key: 'numberActive',
      responsive: ['lg'],
    },
    {
      title: 'Total Units',
      dataIndex: 'nmax',
      key: 'nmax',
   
    },
    {
      title: 'Power of Active',
      dataIndex: 'power_active',
      key: 'powerActive',
      render: (power) => {
        return power.toFixed(2)
      },
    },
  ]

  const interdependencyCheck = () => {
    setInterdependencyButtonChecked(true)
  }

  const onCheckUpstreamData = (data) => {
    setCheckUpstreamMessage(data.message)
    if (data.upstream_dispatched) {
      setPowerValue(data.upstream_power)
      setHeadValue(data.upstream_head)
    }
  }
  const handleSeasonChange = (season) => {
    setInterdependencyButtonChecked(false)
    setSeason(season)
    localStorage.removeItem(`${STEADY_STATE}-season-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-season-${props.tabKey}`,
      JSON.stringify(season),
    )
    setDispatchData()
  }
  const inputValueChange = (v) => {
    setMoreInputValue(v)
    localStorage.removeItem(`${STEADY_STATE}-moreInputValue-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-moreInputValue-${props.tabKey}`,
      JSON.stringify(v),
    )
    inputValues = v
    setInterdependencyButtonChecked(false)
    setDispatchData()
  }
  const sliderChange = (v) => {
    setSliderValue((v / 100) * powerOutput.pmax1)
    setDispatchThreshold(v)
    localStorage.removeItem(`${STEADY_STATE}-dispatchThreshold-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-dispatchThreshold-${props.tabKey}`,
      JSON.stringify(v),
    )

    setInterdependencyButtonChecked(false)
    setDispatchData()
  }

  const onPowerOutput = (data) => {
    setPowerOutput(data)
  }

  const onExportFileMessage = (data) => {
    console.log(data)
    if (data[0]) {
      var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data[3].body], {
        type: 'text/plain;charset=utf-8',
      })
      FileSaver.saveAs(blob, data[2])
      setExportFileMessage(data[1])
    } else setExportFileMessage('No data found to export!')
  }

  const interdependencyData = (data) => {
    setDispatchThreshold(data.dispatch_threshold * 100)
    setSliderValue(data.dispatch_threshold * powerOutput.pmax1)
    setInterdependencyMessage(data.message)
  }
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
    setInterdependencyChecked(e.target.checked)
  }

  const onCurveChange = (e) => {
    console.log(`checked = ${e.target.checked}`)
    setEfficiencyCurveChecked(e.target.checked)
    setDispatchData()
  }

  const dispatchHeadValueChange = (v) => {
    setDispatchHeadValue(v)
    localStorage.removeItem(`${STEADY_STATE}-dispatchHead-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-dispatchHead-${props.tabKey}`,
      JSON.stringify(v),
    )
    setNextStep(false)
    setDispatchExampleClicked(false)
    setDispatchData()
  }

  const headValueChange = (v) => {
    setInterdependencyButtonChecked(false)
    setHeadValue(v)
    localStorage.removeItem(`${STEADY_STATE}-headValue-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-headValue-${props.tabKey}`,
      JSON.stringify(v),
    )
    setDispatchData()
  }
  const powerValueChange = (v) => {
    setInterdependencyButtonChecked(false)
    setPowerValue(v)
    localStorage.removeItem(`${STEADY_STATE}-powerValue-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-powerValue-${props.tabKey}`,
      JSON.stringify(v),
    )
    setDispatchData()
  }

  const calculatePowerOutput = () => {
    setNextStep(true)
    localStorage.removeItem(`${STEADY_STATE}-nextStep-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-nextStep-${props.tabKey}`,
      JSON.stringify(true),
    )
  }

  const onDispatchData = (data) => {
    setDispatchData(data)
    localStorage.removeItem(`${STEADY_STATE}-dispatchData-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-dispatchData-${props.tabKey}`,
      JSON.stringify(data),
    )
  }
  const onDispatchExampleData = (data) => {
    setDispatchExampleData(data)
    localStorage.removeItem(`${STEADY_STATE}-unitDispatchData-${props.tabKey}`)
    localStorage.setItem(
      `${STEADY_STATE}-unitDispatchData-${props.tabKey}`,
      JSON.stringify(data),
    )
  }
  function unitData() {
    return (
      <>
        <Row style={{ padding: '20px' }}>
          <Table
            style={{ backgroundColor: '#f0f0f0', color: '#d8ebfa' }}
            dataSource={dispatchData}
            columns={columns}
          />
        </Row>

        <Row style={{ paddingLeft: '20px', paddingBottom: '30px'  }}>
          <Col>
            <DispatchExample
              path={`dispatch_unit/?project=${props.modelData.project}&head=${dispatchHeadValue}`}
              onDispatchExample={onDispatchExampleData}
            />
          </Col>
        </Row>

        {dispatchExampleData && (
          <>
            <Row style={{ paddingLeft: '20px', paddingTop: '20px' }}>
              <Col>
                <Table
                  style={{ backgroundColor: '#f0f0f0', color: '#d8ebfa' }}
                  dataSource={dispatchExampleData[0]}
                  columns={unitColumns}
                />
              </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
              <Col>
                <Space direction="vertical">
                  <ExportFile
                    path={`export/?project=${props.modelData.project}`}
                    onExportFile={onExportFileMessage}
                    filename={dispatchExampleData[1]}
                  />

                  {exportFileMessage ? (
                    <Alert message={exportFileMessage} />
                  ) : (
                    <></>
                  )}
                </Space>
              </Col>{' '}
            </Row>
          </>
        )}
      </>
    )
  }
  const handleChange = (v) => {
    setSelectedUnit(v)
    setSelectedUnitArray([...selectedUnitArray, v])
  }

  useEffect(() => {
    if (dispatchData) {
      setSelectedUnit(dispatchData[0].group_units[0])
      setSelectedUnitArray([
        ...selectedUnitArray,
        dispatchData[0].group_units[0],
      ])
    }
  }, [dispatchData])

  useEffect(() => {
    setDispatchExampleData('')
  }, [dispatchHeadValue, dispatchThreshold])
  useEffect(() => {
    let sValue = powerOutput.pmax1 * powerOutput.dispatch_threshold
    const updatedMarks = {
      0: 0,
      //100: 100,

      100: { label: <strong>100%</strong> },

      //[maxValue]: {
      //  label: <strong>Pmax ({maxValue ? maxValue.toFixed(2) : ''}MW)</strong>,
      //},
    }
    setSliderValue(sValue)
    setMarks(updatedMarks)
    setHeadValue(powerOutput.interdependency_head_referencevalue?.toFixed(2))
    setPowerValue(powerOutput.interdependency_pgen1_referencevalue?.toFixed(2))
  }, [powerOutput])

  const formatter = (value) => `${value}%`
  return (
    <>
      <div key={props.tabKey}>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px', paddingLeft: '16px'}}>
        <div>
            <h4 style={{ padding: '0px', margin: '0px' }}>
              Dispatch at {props.modelData.model_input_parameters[0].name} [
              {props.modelData.model_input_parameters[0].unit}] (from{' '}
              {props.modelData.model_input_parameters[0].lowerlimit} to{' '}
              {props.modelData.model_input_parameters[0].upperlimit})
            </h4>
          </div>
          <div>
            <InputNumber
              value={dispatchHeadValue}
              onChange={dispatchHeadValueChange}
            />
          </div>
          <div>
            <Button type="primary" onClick={calculatePowerOutput}>
              Next Step
            </Button>
          </div>
        </div>
        {screens.sm? <></>: <hr style={{border: '1px dotted #fff'}}/>}
        {nextStep && (
          <>
            <CalculatePowerOutput
              path={`power_output/?project=${props.modelData.project}&head=${dispatchHeadValue}&general_threshold=${props.sliderValue}&season=${props.season}`}
              onPowerOutput={onPowerOutput}
            />
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px', paddingTop: '20px', paddingLeft: '16px'}}>
            <div >
                <h4 style={{ padding: '0px', margin: '0px' }}>
                  Dispatch with Dispatch Threshold
                </h4>
              </div>
              <div>
                <div style={{ width: '300px' }}>
                  <Slider
                    value={dispatchThreshold}
                    min={0}
                    max={100}
                    onChange={sliderChange}
                    marks={marks}
                    tooltip={{ formatter, placement: 'bottom' }}
                  />
                </div>
              </div>
              <div>
                <h4 style={{ padding: '0px', margin: '0px' }}>
                  Dispatch Threshold = {dispatchThreshold}%
                </h4>
                <h4 style={{ padding: '0px', margin: '0px' }}>
                  Pgen = {sliderValue ? sliderValue.toFixed(2) : ''} MW
                </h4>
                <h4 style={{ padding: '0px', margin: '0px' }}>
                  Pmax = {maxValue ? maxValue.toFixed(2) : ''} MW
                </h4>
              </div>
            </div>
            {screens.sm? <></>: <hr style={{border: '1px dotted #fff'}}/>}
            <Row>
              <h4 style={{ paddingLeft: '16px', margin: '0px' }}>
                Additional Dispatch Input Parameters
              </h4>
            </Row>
            <Row>
              <Col style={{ paddingTop: '20px' }} offset={5}>
                <Descriptions
                  column={1}
                  layout="horizontal"
                  size="small"
                  bordered
                  labelStyle={{color: '#000000'}}
                >
                  {props.modelData.model_input_parameters.map((p, index) => {
                    if (index > startIndex) {
                      inputNames = p.key
                      moreParameters.other_input_names = [inputNames]
                      moreParameters.other_input_values =
                        moreInputValue !== null
                          ? [moreInputValue]
                          : [p.referencevalue]
                      return (
                        <>
                          <Descriptions.Item
                            label={`${p.name} [${p.unit}] (${p.lowerlimit} to ${p.upperlimit})`}
                          >
                            <InputNumber
                              value={
                                moreInputValue !== null
                                  ? moreInputValue?.toFixed(2)
                                  : p.referencevalue?.toFixed(2)
                              }
                              onChange={inputValueChange}
                            />
                          </Descriptions.Item>
                        </>
                      )
                    }
                  })}
                </Descriptions>
              </Col>
            </Row>

            <Row>
              <div style={{ marginTop: '15px' }}>
                <Checkbox
                  checked={interdependencyChecked}
                  onChange={onChange}
                  disabled={!disabled}
                >
                  Check Interdependency between Plants
                </Checkbox>
              </div>
            </Row>
            {checkUpstreamMessage && <Alert message={checkUpstreamMessage} />}
            <Row>
              <Col style={{ paddingTop: '20px' }} offset={5} >
                <Descriptions
                  column={1}
                  layout="horizontal"
                  size="small"
                  labelStyle={{color: '#000000'}}
                  bordered
                >
                  <Descriptions.Item label="Name of Upstream Plant">
                    {props.modelData.interdependency_project}
                  </Descriptions.Item>

                  <Descriptions.Item label="Season">
                    {interdependencyChecked ? (
                      <Select
                        allowClear
                        style={{
                          width: '100%',
                          height: '100%',
                          overflowY: 'scroll',
                        }}
                        placeholder="Select season"
                        onChange={handleSeasonChange}
                        options={items}
                        defaultValue={
                          props.season
                            ? items.filter(
                                (item) => item.value === props.season,
                              )
                            : items.filter((item) => item.value === 'Spring')
                        }
                      >
                        <Space>
                          Select season
                          <DownOutlined />
                        </Space>
                      </Select>
                    ) : (
                      props.season
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      powerOutput.interdependency_head_lowerlimit &&
                      powerOutput.interdependency_head_upperlimit
                        ? `Head of upstream Plant [ft](from ${powerOutput.interdependency_head_lowerlimit} to ${powerOutput.interdependency_head_upperlimit})`
                        : `Head of upstream Plant [ft](from - to -)`
                    }
                  >
                    {interdependencyChecked ? (
                      <InputNumber
                        value={headValue}
                        onChange={headValueChange}
                      />
                    ) : (
                      powerOutput.interdependency_head_referencevalue?.toFixed(
                        2,
                      )
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      powerOutput.interdependency_pgen1_lowerlimit &&
                      powerOutput.interdependency_pgen1_upperlimit
                        ? `Power of upstream Plant [MW](from ${powerOutput.interdependency_pgen1_lowerlimit} to ${powerOutput.interdependency_pgen1_upperlimit})`
                        : `Power of upstream Plant [MW](from - to -)`
                    }
                  >
                    {interdependencyChecked ? (
                      <InputNumber
                        value={powerValue}
                        onChange={powerValueChange}
                      />
                    ) : (
                      powerOutput.interdependency_pgen1_referencevalue?.toFixed(
                        2,
                      )
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              {interdependencyChecked && (
                <Col style={{ paddingTop: '50px', paddingLeft: '20px' }}>
                  <Row>
                    <CheckUpstream
                      path={`precheck_upstream/?project=${props.modelData.project}&upstream_project=${props.modelData.interdependency_project}`}
                      onCheckUpstream={onCheckUpstreamData}
                      disabled={!disabled}
                    />
                  </Row>
                  <Row style={{ paddingTop: '30px' }}>
                    <Button
                      type="primary"
                      onClick={interdependencyCheck}
                      disabled={!disabled}
                    >
                      Check Interdependency
                    </Button>
                  </Row>
                </Col>
              )}
            </Row>
            {console.log(season)}
            {interdependencyButtonChecked && (
              <>
                <InterdependencyCheck
                  path={`interdependency/?project=${
                    props.modelData.project
                  }&season=${season}&upstream_project=${
                    props.modelData.interdependency_project
                  }&upstream_power=${powerValue}&upstream_head=${headValue}&dispatch_threshold=${
                    dispatchThreshold / 100
                  }&pmax=${maxValue}`}
                  onInterdependencyCheck={interdependencyData}
                />

                {interdependencyMessage && (
                  <Alert message={interdependencyMessage} />
                )}
              </>
            )}
            <Row style={{ paddingTop: '30px', paddingLeft: '20px' }}>
              <UnitLevelDispatch
                path={`dispatch_group/?project=${
                  props.modelData.project
                }&head=${dispatchHeadValue}&Pmax1=${
                  powerOutput.pmax1
                }&dispatch_threshold=${
                  dispatchThreshold / 100
                }&check_efficiency_curves=${efficiencyCurveChecked}`}
                parameters={moreParameters}
                onDispatch={onDispatchData}
              />
              <Col offset={2} style={{ paddingTop: '30px' }}>
                <Checkbox
                  checked={efficiencyCurveChecked}
                  onChange={onCurveChange}
                >
                  Check with Efficiency Curves (if available)
                </Checkbox>
              </Col>
            </Row>

            {dispatchData && unitData()}
          </>
        )}
      </div>
    </>
  )
}

export default ChildPlantTab
