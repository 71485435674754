import React, { useState, useEffect } from 'react'
import { Descriptions, Select, Modal } from 'antd'
import EfficiencyCurveData from './EfficiencyCurveData'
const { Option } = Select

function PowerplantTable(props) {
  const [selectedGeneratorValue, setSelectedGeneratorValue] = useState(
    'Select Generator Unit',
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState('')

  const handleChange = (v) => {
    if (v !== undefined && props.plant.generators !== null) {
      setSelectedGeneratorValue(v)
      setSelectedUnit(props.plant.generators.find((u) => u.unit_ID === v))
      setIsModalOpen(true)
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const title = (
    <h3 style={{ margin: '0 0 0 0', fontSize: '15px', textAlign: 'center' }}>
      Power Plant
    </h3>
  )
  return (
    <>
      <Descriptions
        title={title}
        column={1}
        layout="horizontal"
        size="small"
        bordered
      >
        <Descriptions.Item label="Plant Code">
          {props.plant.project_name}
        </Descriptions.Item>
        <Descriptions.Item label="Name">
          {props.plant.plant_name}
        </Descriptions.Item>
        <Descriptions.Item label="Nominal Head">
          {props.plant.nominal_head_ft} ft
        </Descriptions.Item>
        <Descriptions.Item label="Area Name">
          {props.plant.area_name}
        </Descriptions.Item>
        <Descriptions.Item label="Area Number">
          {props.plant.area_no}
        </Descriptions.Item>
        <Descriptions.Item label="Total Units">
          {props.plant.total_units}
        </Descriptions.Item>
        <Descriptions.Item label="Generator Capacity">
          {props.plant.gen_capacity_max_MW !== null
            ? props.plant.gen_capacity_max_MW?.toFixed(2)
            : props.plant.gen_capacity_max_MW}{' '}
          MW
        </Descriptions.Item>
        <Descriptions.Item label="Generator Units">
          <Select
            value={null}
            dropdownStyle={{ backgroundColor: '#d8ebfa' }}
            allowClear
            placement="topLeft"
            bordered={false}
            style={{
              background: '#d8ebfa',
            }}
            placeholder="Select Generator Unit"
            onChange={handleChange}
          >
            {props.plant.generators?.map((unit) => (
              <Option key={unit.unit_ID} value={unit.unit_ID}>
                {unit.unit_ID}
              </Option>
            ))}
          </Select>
        </Descriptions.Item>
      </Descriptions>
      <Modal
        title={`Generator Unit ${selectedUnit.unit_ID}`}
        open={isModalOpen}
        style={{ top: 20 }}
        width={750}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <>
          <Descriptions column={1} layout="horizontal" size="small" bordered>
            <Descriptions.Item label="Unit ID">
              {selectedUnit.unit_ID}
            </Descriptions.Item>
            <Descriptions.Item label=" Project Name">
              {selectedUnit.project_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bus Name">
              {selectedUnit.bus_name} ft
            </Descriptions.Item>
            <Descriptions.Item label="Bus Number">
              {selectedUnit.bus_number}
            </Descriptions.Item>
            <Descriptions.Item label="ID">{selectedUnit.ID}</Descriptions.Item>
            <Descriptions.Item label="Nominal PMax">
              {selectedUnit.nominal_pmax_MW} MW
            </Descriptions.Item>
            <Descriptions.Item label="SCADA Bus Number">
              {selectedUnit.SCADA_bus_number}
            </Descriptions.Item>
            <Descriptions.Item label="SCADA Bus ID">
              {selectedUnit.SCADA_bus_ID}
            </Descriptions.Item>
          </Descriptions>
          <EfficiencyCurveData
            path={`efficiencycurve/?unit_ID=${selectedUnit.unit_ID}`}
            data=""
            //path={`efficiencycurve/?unit_ID=41721-1`}
          />
        </>
      </Modal>
    </>
  )
}

export default PowerplantTable
