import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Image } from 'antd'
import logo from '../../assets/hydrowires-logo.png';

import './SiteFooter.css'

const { Footer } = Layout

const SiteFooter = () => {
  return (
    <Footer className='site-footer'>
      <Row gutter={[16, 16]} justify="space-around">
        <Col xs={24} sm={24} md={8} className='left-col'>
          <h3 style={{color: '#ffffff'}}>Resources</h3>     
          <p><a href="https://www.pnnl.gov/projects/improving-hydropower-models-support-resilient-grid" className="site-footer-link" target="_blank" rel="noopener noreferrer">Hy-DAT News</a></p>
          <p><a href="https://www.energy.gov/eere/water/hydrowires-initiative" className="site-footer-link" target="_blank" rel="noopener noreferrer">HydroWIRES Initiative</a></p>
          <p><a href="https://www.nrel.gov/about/energyexecs.html" className="site-footer-link" target="_blank" rel="noopener noreferrer">Executive Energy Leadership Academy</a></p>
        </Col>
        <Col xs={24} sm={24} md={8} className='middle-col'>
          <h3 style={{color: '#ffffff'}}>Produced By</h3>
          <p><a href="https://www.pnnl.gov/" className="site-footer-link" target="_blank" rel="noopener noreferrer">Pacific Northwest National Laboratory</a></p>
          <p><a href="https://www.energy.gov/" className="site-footer-link" target="_blank" rel="noopener noreferrer">US Department of Energy</a></p>
        </Col>
        <Col xs={24} sm={24} md={8} className='right-col'>
          <a href="https://www.energy.gov/eere/water/hydrowires-initiative" target="_blank" rel="noopener noreferrer">
          <img
            width={175}
            src={logo}
            alt="Hydrowires Logo"
          />
          </a>
        </Col>
      </Row>
    </Footer>
  );
};

export default SiteFooter;