// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-div {
  padding: 0;
  padding-top: 0;

  background: colorBgContainer;
  background-color: #d8ebfa;

}



.ant-btn-text {
  color: #ffffff ;

}

.ant-btn-text:hover {
  color: #000000  !important; 
  background-color: #FFFFFF !important;


}

.ant-btn-text:active {
  color: #000000  !important; 


}


.logo {
  height: '30px';
  width: '30px'
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 60px;
  background-color: #102C57;
}

.layout {
  padding: 0 5px 0 10;
  background-color: #d8ebfa;
  overflow: auto;
}

.hamburger-menu {
  display: block;
  cursor: pointer;
  @media screen and (min-width: 1080px) {

    display: none;
 }
}
.header-buttons {
  display: block;
  cursor: pointer;
  @media screen and (max-width: 1080px) {

    display: none;
 }
}`, "",{"version":3,"sources":["webpack://./src/components/HydrowireGUI/Hydrowires.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;;EAEd,4BAA4B;EAC5B,yBAAyB;;AAE3B;;;;AAIA;EACE,eAAe;;AAEjB;;AAEA;EACE,0BAA0B;EAC1B,oCAAoC;;;AAGtC;;AAEA;EACE,0BAA0B;;;AAG5B;;;AAGA;EACE,cAAc;EACd;AACF;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf;;IAEE,aAAa;CAChB;AACD;AACA;EACE,cAAc;EACd,eAAe;EACf;;IAEE,aAAa;CAChB;AACD","sourcesContent":[".content-div {\n  padding: 0;\n  padding-top: 0;\n\n  background: colorBgContainer;\n  background-color: #d8ebfa;\n\n}\n\n\n\n.ant-btn-text {\n  color: #ffffff ;\n\n}\n\n.ant-btn-text:hover {\n  color: #000000  !important; \n  background-color: #FFFFFF !important;\n\n\n}\n\n.ant-btn-text:active {\n  color: #000000  !important; \n\n\n}\n\n\n.logo {\n  height: '30px';\n  width: '30px'\n}\n\n.header {\n  position: sticky;\n  top: 0;\n  z-index: 1;\n  width: 100%;\n  height: 60px;\n  background-color: #102C57;\n}\n\n.layout {\n  padding: 0 5px 0 10;\n  background-color: #d8ebfa;\n  overflow: auto;\n}\n\n.hamburger-menu {\n  display: block;\n  cursor: pointer;\n  @media screen and (min-width: 1080px) {\n\n    display: none;\n }\n}\n.header-buttons {\n  display: block;\n  cursor: pointer;\n  @media screen and (max-width: 1080px) {\n\n    display: none;\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
