// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-footer {
    text-align: left;
    z-index: 2;
    background-color: #000000;
  }
    
.site-footer-link {
  color: #ffffff;
  text-decoration: none;
}

.site-footer-link:hover {
  text-decoration: underline;
}

.right-col {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/SiteFooter/SiteFooter.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,yBAAyB;EAC3B;;AAEF;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".site-footer {\n    text-align: left;\n    z-index: 2;\n    background-color: #000000;\n  }\n    \n.site-footer-link {\n  color: #ffffff;\n  text-decoration: none;\n}\n\n.site-footer-link:hover {\n  text-decoration: underline;\n}\n\n.right-col {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
