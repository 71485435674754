import React, { useState } from 'react'
import Map from './Map'
import Layers from './Layers'
import TileLayer from './TileLayer'
import ProjectVectorLayer from './ProjectVectorLayer'
import { fromLonLat } from 'ol/proj'
import osm from './osm'

const MapOpenLayer = (props) => {
  let cet = [-119.149573, 47.763957]
  const [center, setCenter] = useState(cet)
  const [zoom, setZoom] = useState(7)
  const [clickedFeature, setClickedFeature] = useState(null)
  let showMarker = false

  if (props.plants !== undefined) {
    showMarker = true
  }

  const handleMarkerEvent = (feature) => {
    setClickedFeature(feature)
    props.onMarkerClick(feature)
  }

  return (
    <div>
      <Map center={fromLonLat(center)} zoom={zoom}>
        <Layers>
          <TileLayer source={osm()} zIndex={0} />

          {showMarker &&
            props.plants.map((plant) => {
              return (
                <ProjectVectorLayer
                  marker={[plant[0].longitude, plant[0].latitude]}
                  markerId={plant[0].project_name}
                  onFeatureClick={handleMarkerEvent}
                />
              )
            })}
        </Layers>
      </Map>
    </div>
  )
}

export default MapOpenLayer
