
import React, { createContext, useState } from 'react';

const AppContext = createContext();

const StateProvider = ({ children }) => {
  const [plotData, setPlotData] = useState(null);
  const [variablesData, setVariablesData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const savePlotData = (plotName, data) => {
    setPlotData((prevData) => ({
      ...prevData,
      [plotName]: data,
    }));
  };
  const loginContext = () => {
    setIsLoggedIn(true);
    console.log("in login context")
  };

  const logoutContext = () => {
    setIsLoggedIn(false);
  };


  const saveVariablesData = (data) => {
    setVariablesData(data);
  };

  const resetAppState = () => {

    localStorage.clear();
  };

  return (
    <AppContext.Provider value={{ plotData, savePlotData, variablesData, saveVariablesData, resetAppState, loginContext, logoutContext, isLoggedIn }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, StateProvider };
