import { createRoot } from 'react-dom/client';
import React from 'react';
import './index.css';
import App from './App';

const root = createRoot(document.getElementById('root'));

root.render(
  <App />
);


// import React, { useMemo } from 'react'
// import ReactDOM from 'react-dom/client'
// import './index.css'
// import TimeSeriesTab from './components/TimeSeriesTab'
// import SteadyStateDispatchTab from './components/SteadyStateDispatchTab'

// import HydrowireGUI from './components/HydrowireGUI/HydrowireGUI'
// import reportWebVitals from './reportWebVitals'
// import { BrowserRouter, Outlet, Routes, Route } from 'react-router-dom'
// import { QueryClient, QueryClientProvider } from 'react-query';
// import { StateProvider } from './components/AppContext'
// const queryClient = new QueryClient();


// function MemoizeComponent(Component) {
//   return useMemo(() => <Component />, [])
// }
// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <>
//   <StateProvider>
//   <QueryClientProvider client={queryClient}>
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<HydrowireGUI />}>
//           <Route path="/timeseries" element={<TimeSeriesTab />} />
//           <Route
//             path="/steadystatedispatch"
//             element={<SteadyStateDispatchTab />}
//           />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//     </QueryClientProvider>
//     </StateProvider>

//     {/*<HydrowireGUI /> */}
//   </>,
// )

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
