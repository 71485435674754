import { useState, useEffect } from 'react'
import { Row, Col, Button,  } from 'antd'
const DispatchAll = (props) => {
  const body = props.plant
  const [loading, setLoading] = useState(false);
  let plantArray = []
  if (Array.isArray(props.plant)) {
    plantArray = props.plant
  } else plantArray.push(props.plant)

  const fetchData = async () => {
    setLoading(true); 
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(plantArray),
      })
      const data = await response.json()
      props.onDispatchAll(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false); // Set loading to false when the fetch is complete
    }
  }
  return (
    <>
      <Row style={{ padding: '15px' }}>
        <Col>
          <Button type="primary" onClick={fetchData} disabled={props.button}>
          {loading ? 'Loading...' : 'Dispatch All Selected Plants'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default DispatchAll;
