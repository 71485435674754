import { useState, useEffect } from 'react'
import { Row, Col, Button,  } from 'antd'
const ExportAll = (props) => {
  const body = props.plant

   let plantArray = []
  if (Array.isArray(props.plant)) {
    plantArray = props.plant
  } else plantArray.push(props.plant)

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(plantArray),
      })
      const data = await response.json()
      props.onDispatchAll(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  return (
    <>
      <Row style={{ padding: '15px' }}>
        <Col>
          <Button type="primary" onClick={fetchData}>
          Export All Dispatches
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ExportAll;
