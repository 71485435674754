import { Select, DatePicker, Form, Button, Tooltip, Space } from 'antd'
import { useEffect, useState } from 'react'
import VariablesData from './VariablesData'
import TimeSeries from './TimeSeries'
import '../styles/dropdown.less'
import dayjs from 'dayjs'
import moment from 'moment'
import {VARIABLES, SELECTED_VARIABLES, TIMESERIES_PLOT_SUBMITTED, SELECTED_DATES} from '../constants';

import { useContext } from 'react';
import { AppContext } from './AppContext';
const { RangePicker } = DatePicker
let ts
const dateFormat = 'YYYY-MM-DD'
let startDateStr, endDateStr


function Variables(props) {


  const variablesStorage = localStorage.getItem(VARIABLES)
  const [variables, setVariables] = useState(variablesStorage?
    JSON.parse(variablesStorage): ''
  )
  const [plotsData, setPlotsData] = useState()

  const selectedVariablesStorage = localStorage.getItem(SELECTED_VARIABLES)
  const [selectedVariable, setSelectedVariable] = useState(selectedVariablesStorage?
    JSON.parse(selectedVariablesStorage): []
  )
  const [rangeValue, setRangeValue] = useState([])

  const [variableSelected, setVariableSelected] = useState(false)

  const formSubmittedStorage = localStorage.getItem(TIMESERIES_PLOT_SUBMITTED)
  const [formSubmitted, setFormSubmitted] = useState(formSubmittedStorage?
    JSON.parse(formSubmittedStorage): false
  )

  const [dateSelected, setDateSelected] = useState(false)
  const [form] = Form.useForm()
  const variableOptions = []

  const selectedDatesStorage = localStorage.getItem(SELECTED_DATES)
  const { variablesData } = useContext(AppContext);

  if (selectedDatesStorage) {
  
      const dateArray = JSON.parse(selectedDatesStorage)
      if(dateArray.length === 1) {
      startDateStr = new Date(dateArray[0].split(',')[1])
        .toISOString()
        .slice(0, 10)

      endDateStr = new Date(dateArray[0].split(',')[3])
        .toISOString()
        .slice(0, 10)
      }
    else if (dateArray.length === 2) {

      startDateStr = dateArray[0].slice(0, 10)
  
      endDateStr = dateArray[1].slice(0, 10)
    }
   }
  
  const [selectedDates, setSelectedDates] = useState(startDateStr && endDateStr? [
    dayjs(startDateStr, dateFormat),
    dayjs(endDateStr, dateFormat),
  ]: [])

 const handleReset = (form) => {
  // Reset the form fields
  form.resetFields();
};

  useEffect(() => {
    localStorage.removeItem(SELECTED_VARIABLES)
  
    if (selectedVariable !== undefined && selectedVariable !== null)
      localStorage.setItem(
        SELECTED_VARIABLES,
        JSON.stringify(selectedVariable),
      )

  }, [selectedVariable])


  useEffect(() => {
 
    localStorage.removeItem(SELECTED_DATES)

    if (selectedDates !== null)
      localStorage.setItem(SELECTED_DATES, JSON.stringify(selectedDates))
  }, [selectedDates])

  const handleChange = (value) => {
    setFormSubmitted(false)
    setSelectedVariable(value)
    if (value !== null && value !== undefined && value.length > 0) {
      setVariableSelected(true)
    } else setVariableSelected(false)
  }

  const getVariables = (pData) => {
    setVariables(pData)
    localStorage.removeItem(VARIABLES)
    localStorage.setItem(VARIABLES, JSON.stringify(pData))
  }

  const onFinish = (values) => {
    setFormSubmitted(false)

    form.setFieldsValue(values)

    if (selectedVariable && selectedDates?.length > 0) {
      setFormSubmitted(true)
      localStorage.removeItem(TIMESERIES_PLOT_SUBMITTED)
      localStorage.setItem(TIMESERIES_PLOT_SUBMITTED, JSON.stringify(true))
    } else setFormSubmitted(false)
  }


  
  if (variables !== '' && variables !== undefined && variables.length > 0) {
    variables[0].map((variable) => {
      variableOptions.push({
        label: variable.name,
        value: variable.key,
      })
    })
  }

  return (
    <>
 
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        layout="inline"
        style={{margin: '0 20px 20px 20px'}}
      >

        <Form.Item
          shouldUpdate
          name="date"
          rules={[
            {
              required: false,
              message: 'Please select Start date and End date',
            },
          ]}
        >
          <Tooltip title={`Pick a date between ${new Date(variables[1]).toLocaleDateString()} and ${new Date(variables[2]).toLocaleDateString()}`}>

          <RangePicker
           style={{margin: '10px'}}
            disabledDate={(current) =>
              current &&
              (current < dayjs(variables[1]) || current > dayjs(variables[2]))
            }
            format={dateFormat}
            defaultValue={selectedDates}
            onChange={(e) => {
              setFormSubmitted(false)
              setSelectedDates(e ? [e.toString()] : [])

              if (e !== undefined && e !== null && e.length > 0) {
                setDateSelected(true)
              } else setDateSelected(false)
            }}
          />
          </Tooltip>
        </Form.Item>

       {!variables && <VariablesData
          path="plant_variables/"
          onVariableCallback={getVariables}
        />}
    <Tooltip title="Please select one or more varibles">
        <Form.Item
          shouldUpdate
          name="variable"
          rules={[
            {
              required: false,
              message: 'Please select Variable',
            },
          ]}
        >
          <Select
            style={{ width: '200px', height: '32px', overflowY: 'scroll', margin: '10px' }}
            mode="multiple"
            allowClear
            defaultValue={selectedVariable}
            placeholder="Please select variables"
            onChange={handleChange}
            options={variableOptions}
          />
        </Form.Item>
        </Tooltip>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{margin: '10px'}}
            //onClick={formSubmit}
          >
            Submit
          </Button>
        </Form.Item>

      </Form>
      {formSubmitted && (
        <>
          {selectedVariable.length > 1 ? (
            selectedVariable.map((variable, index) => {
              const v = variables[0].find((p) => p.key === variable)

              return (
                <>
                  <TimeSeries
                    path={`plant_timeseries/?variable=${variable}`}
                    variableName={variable}
                    variableLabel={v.name}
                    variableUnit={v.unit}
                    plantName={props.plantCodes}
                    dates={selectedDates}
                  />
                </>
              )
            })
          ) : (
            <>
              <TimeSeries
                path={`plant_timeseries/?variable=${selectedVariable}`}
                variableName={selectedVariable}
                variableLabel={
                  variables[0].find((p) => p.key === selectedVariable[0]).name
                }
                variableUnit={
                  variables[0].find((p) => p.key === selectedVariable[0]).unit
                }
                plantName={props.plantCodes}
                dates={selectedDates}

              />
            </>
          )}

        </>
      )}
    </>
  )
}

export default Variables;