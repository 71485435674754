import { Select, Form, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import '../styles/dropdown.less'
import Projects from './Projects'
import { PLANTS_DATA } from '../constants'
function Plants(props) {
  const plantsDataStorage = localStorage.getItem(PLANTS_DATA)

  const [projectData, setProjectData] = useState(
    plantsDataStorage ? JSON.parse(plantsDataStorage) : [],
  )
  const [receivedProjectData, setReceivedProjectData] = useState(false)
  const [plantSelected, setPlantSelected] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const opt = []
  const [form] = Form.useForm()

  const customTagRender = (props) => {
    const { label, closable, onClose } = props

    return (
      <div
        style={{
          display: 'inline-block',
          maxHeight: '32px',
          margin: '0px 8px',
          maxWidth: '250px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'scroll',
        }}
      >
        <span>{label}</span>
        {closable && (
          <span onClick={onClose} style={{ marginLeft: 8, color: '#999' }}>
            ×
          </span>
        )}
      </div>
    )
  }

  const handleChange = (value) => {
    props.selectPlants(value, projectData)
    if (value !== null && value !== undefined && value.length > 0) {
      setPlantSelected(true)
    } else setPlantSelected(false)
  }

  const handleCallback = (pData) => {
    setProjectData(pData)
    setReceivedProjectData(true)
  }

  const onFinish = (values) => {
    setFormSubmitted(false)

    form.setFieldsValue(values)

    if (receivedProjectData && setPlantSelected) {
      setFormSubmitted(true)
    } else setFormSubmitted(false)
  }
  if (projectData !== '') {
    projectData.map(({ plant_name }) => {
      opt.push({
        label: plant_name,
        value: plant_name,
      })
    })
  }

  return (
    <div>
      <Projects path="projects" parentCallback={handleCallback} />
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          shouldUpdate
          name="variable"
          rules={[
            {
              required: true,
              message: 'Please select Plants',
            },
          ]}
        >
          {projectData.length > 0 && (
            <Tooltip title={props.tooltipMessage}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '80%', height: '100%', overflowY: 'scroll' }}
                placeholder="Please select Plants"
                onChange={handleChange}
                defaultValue={props.selectedPlants}
                //value={props.selectedPlants}
                options={opt}
              />
              </Tooltip>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
export default Plants
