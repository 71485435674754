import { useState, useCallback } from 'react'
import { Button, Space } from 'antd'
import { useCurrentPng } from 'recharts-to-png'
import FileSaver from 'file-saver'

import {
  ScatterChart,
  LineChart,
  Line,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Dot,
} from 'recharts'

const colors = [
  '#8884d8',
  '#FFA500',
  '#0000FF',
  '#964B00',
  '#000000',
  '#258025',
  '#eb34d8',
  '#e02f10',
  '#7b42ed',
  '#06bcbf',
  '#991539',
]
function EfficiencyCurve(props) {
  var receivedTSData = false
  const [getAreaPng, { ref: areaRef }] = useCurrentPng()

  const handleExport = (data, variable) => {
    var blob = new Blob([JSON.stringify(data)], {
      type: 'text/plain;charset=utf-8',
    })
    FileSaver.saveAs(blob, `${variable}.json`)
  }
  const handleAreaDownload = useCallback(
    async (variable) => {
      const png = await getAreaPng()
      if (png) {
        FileSaver.saveAs(png, `${variable}.png`)
      }
    },
    [getAreaPng],
  )

  if (props.data !== undefined && props.data !== '' && props.data.length > 0) {
    receivedTSData = true
  }

  const RenderDot = ({ cx, cy, color }) => {
    return <Dot cx={cx} cy={cy} fill={color} r={3} />
  }
  return (
    <>
      {receivedTSData && (
        <>
          <h4 style={{ marginLeft: '250px' }}>{props.variable}</h4>
          <div className="area-chart">
            <ScatterChart
              width={props.width}
              height={props.height}
              ref={areaRef}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="power"
                type="number"
                name="power"
                label={{
                  value: 'power [MW]',
                  offset: 0,
                  position: 'insideBottom',
                }}
              />
              <YAxis
                dataKey="efficiency"
                type="number"
                name="efficiency"
                label={{
                  value: 'efficiency[%]',
                  offset: 15,
                  position: 'insideTop',
                }}
                tickFormatter={(Estimated_minutes) =>
                  Estimated_minutes.toFixed(2)
                }
              />
              <Tooltip />
              <Legend />
              {props.data[0].map((d, index) => (
                <Scatter
                  name={`[${d.head - 5}, ${d.head + 5}]`}
                  type="monotone"
                  data={d.curve}
                  fill={colors[index]}
                  shape={<RenderDot color={colors[index]} />}
                />
              ))}
            </ScatterChart>

            {/* <LineChart
              width={props.width}
              height={props.height}
              ref={areaRef}
              //data={props.data[0][0].curve}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="power" type="number" label={{
                  value: 'power [MW]', offset: 0, position: "insideBottom"
                }}/>
              <YAxis type="number"
                label={{
                  value: 'efficiency[%]',
                  offset: 10,
                  position: 'insideTop',
                }}
                                tickFormatter={(Estimated_minutes) =>
                  Estimated_minutes.toFixed(2)
                }
              ></YAxis>
              <Tooltip />
              <Legend />
              {props.data[0].map((d, index) => (
        <Line key={index} name={`[${d.head-5}, ${d.head+5}]`} type="monotone" data={d.curve} dataKey="efficiency" stroke={colors[index]}  dot={false} />
      ))}
              
              
              </LineChart>*/}

            <br />
            <Space style={{ marginLeft: '80px' }}>
              <Button
                type="primary"
                onClick={() => handleAreaDownload('efficiency')}
              >
                Download Efficiency Chart
              </Button>
              <Button
                type="primary"
                onClick={() => handleExport(props.data[0][0], 'efficiency')}
              >
                Export Efficiency Data
              </Button>
            </Space>
          </div>
        </>
      )}
    </>
  )
}
export default EfficiencyCurve
