import { useState, useEffect } from 'react'
import { Row, Button, Space, Input } from 'antd'

const ExportFile = (props) => {
  const [filename, setFilename] = useState(props.filename)

  //const filenameChange = (event) => {
   
  //  setFilename(event.target.value)
 // }
  const exportFile = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}&file_name=${filename}`)
      const data = await response.json()
      props.onExportFile(data) // Pass the fetched data to the callback in App.
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  return (
    <Row style={{ paddingTop: '20px' }}>
      <Space>
      <Button type="primary" onClick={exportFile}>
        Export CSV File
      </Button>
     {/* <Input style={{width: '450%'}} defaultValue={filename} onChange={filenameChange}></Input> */}
      </Space>
    </Row>
  )
}

export default ExportFile
