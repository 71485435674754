import { useState, useEffect } from 'react'
import { MODEL_DATA, STEADY_STATE } from '../constants';

const LoadModels = (props) => {
  const [data, setData] = useState('')
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const modelDataStorage = localStorage.getItem(`${STEADY_STATE}-${MODEL_DATA}`)
  const [modelData, setModelData] = useState(
    modelDataStorage ? JSON.parse(modelDataStorage) : null,
  )


  //const plantArray = ["GCL", "CHJ", "WEL", "RRH", "WAN"]

  let plantArray = []
  if (Array.isArray(props.plant)) {
    plantArray = props.plant
  } else plantArray.push(props.plant)

  useEffect(() => {
    if (!modelData) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/${props.path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(plantArray),
          
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          setData(result)
          props.onModeldata(result)
        },

        (error) => {
          setIsLoaded(true)
          setError(error)
        },
      )
      }
      else {setData(modelData)
        setIsLoaded(true)
      }
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else if (data) {
    return <div></div>
  }
  return <div />
}

export default LoadModels
