import {
  Row,
  Col,
  InputNumber,
  Slider,
  Descriptions,
  Select,
  Alert,
  Table,
  Grid
} from 'antd'
const { useBreakpoint } = Grid;
function ChildPlantTabAll(props) {
  const screens = useBreakpoint();
  console.log(props.tabKey)
  const powerOutput = props.allData.response_of_power_output

  const dispatchHeadValue =
    props.modelData.model_input_parameters[0].referencevalue

  const dispatchThreshold = props.sliderValue * 100
  const dispatchDataGroup = props.allData.response_of_dispatch_group
  const dispatchDataUnit = props.allData.response_of_dispatch_unit

  const selectedUnit = dispatchDataGroup[0].group_units[0]
  const exportFileMessage = props.allData.response_of_export
  const interdependencyMessage = props.allData.response_of_interdependency

  const sliderValue = Number(powerOutput.pmax1 * powerOutput.dispatch_threshold)

  const maxValue = powerOutput.pmax1
  const startIndex = 1

  const unitColumns = [
    {
      title: 'Unit ID',
      dataIndex: 'unit_ID',
      key: 'unit_ID',
    },
    {
      title: 'Pmax [MW]',
      dataIndex: 'pmax',
      key: 'pmax',
    },
    {
      title: 'Dispatch Status',
      dataIndex: 'dispatch_status',
      key: 'dispatch_status',
    },
    {
      title: 'Dispatch Power',
      dataIndex: 'dispatch_power',
      key: 'dispatch_power',
      render: (power) => {
        return power.toFixed(2)
      },
    },
    {
      title: 'Existing case: Status',
      dataIndex: 'previous_status',
      key: 'previous_status',
      responsive: ['lg']
    },
    {
      title: 'Existing case: Power',
      dataIndex: 'previous_power',
      key: 'previous_power',
      responsive: ['lg']
    },
  ]
  const columns = [
    {
      title: 'Group',
      dataIndex: 'group_name',
      key: 'group_name',
    },
   {
      title: 'Available Units',
      dataIndex: 'group_units',
      key: 'group_units',
      responsive: ['lg'],
      render: (group_units) => {
        return (
          <Select
            style={{ width: '200px', height: '32px', overflowY: 'scroll' }}
            allowClear
            //value={setSelectedUnit(group_units[0])}
            defaultValue={group_units[0]}
            options={group_units.map((unit) => ({ label: unit, value: unit }))}
          />
        )
      },
    },
    {
      title: 'Pmax',
      dataIndex: 'pmax',
      key: 'pmax',
      render: (pmax) => {
        return pmax.toFixed(2)
      }
    },
    {
      title: 'Number of Active',
      dataIndex: 'number_active',
      key: 'numberActive',
      responsive: ['lg'],
    },
    {
      title: 'Total Units',
      dataIndex: 'nmax',
      key: 'nmax',
    },
    {
      title: 'Power of Active',
      dataIndex: 'power_active',
      key: 'powerActive',
      render: (power) => {
        return power.toFixed(2)
      },
    },
  ]

  const items = [
    {
      label: 'Spring',
      value: 'Spring',
    },
    {
      label: 'Summer',
      value: 'Summer',
    },
    {
      label: 'Fall',
      value: 'Fall',
    },
    {
      label: 'Winter',
      value: 'Winter',
    },
  ]

  function unitData() {
    return (
      <>
        <Row style={{ padding: '20px' }}>
          <Table
            style={{ backgroundColor: '#f0f0f0', color: '#d8ebfa' }}
            dataSource={dispatchDataGroup}
            columns={columns}
          />
        </Row>

        <Row style={{ padding: '20px' }}>
          <Col>
            {selectedUnit && (
              <Table
                style={{ backgroundColor: '#f0f0f0', color: '#d8ebfa' }}
                dataSource={dispatchDataUnit}
                columns={unitColumns}
              />
            )}
          </Col>
          <Col offset={2} span={10} style={{ paddingTop: '30px' }}>
            {exportFileMessage ? <Alert message={exportFileMessage} /> : <></>}
          </Col>
        </Row>
      </>
    )
  }

  const marks = {
    0: 0,
    100: { label: <strong>100%</strong> },
  }

  const headValue = powerOutput.interdependency_head_referencevalue?.toFixed(2)
  const powerValue = powerOutput.interdependency_pgen1_referencevalue?.toFixed(
    2,
  )

  const formatter = (value) => `${value}%`
  return (
    <>
      <div key={props.tabKey}>
      <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px', paddingLeft: '16px'}}>
      <div>
            <h4 style={{ padding: '0px', margin: '0px' }}>
              Dispatch at {props.modelData.model_input_parameters[0].name} [
              {props.modelData.model_input_parameters[0].unit}] (from{' '}
              {props.modelData.model_input_parameters[0].lowerlimit} to{' '}
              {props.modelData.model_input_parameters[0].upperlimit})
            </h4>
          </div>
          <div>
            <InputNumber
              value={props.modelData.model_input_parameters[0].referencevalue}
            />
          </div>
        </div>
        {screens.sm? <></>: <hr style={{border: '1px dotted #fff'}}/>}
        <>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px', paddingTop: '20px', paddingLeft: '16px'}}>
        <div >
              <h4 style={{ padding: '0px', margin: '0px' }}>
                Dispatch with Dispatch Threshold
              </h4>
            </div>
            <div>
              <div style={{ width: '300px' }}>
                <Slider
                  value={dispatchThreshold}
                  min={0}
                  max={100}
                  marks={marks}
                  tooltip={{ formatter, placement: 'bottom' }}
                />
              </div>
            </div>
            <div>
              <h4 style={{ padding: '0px', margin: '0px' }}>
                Dispatch Threshold = {dispatchThreshold}%
              </h4>
              <h4 style={{ padding: '0px', margin: '0px' }}>
                Pgen = {sliderValue ? sliderValue.toFixed(2) : ''} MW
              </h4>
              <h4 style={{ padding: '0px', margin: '0px' }}>
                Pmax = {maxValue ? maxValue.toFixed(2) : ''} MW
              </h4>
            </div>
          </div>
          {screens.sm? <></>: <hr style={{border: '1px dotted #fff'}}/>}
          <Row>
            <h4 style={{ paddingLeft: '16px', margin: '0px' }}>
              Additional Dispatch Input Parameters
            </h4>
          </Row>
          <Row>
            <Col style={{ paddingTop: '20px' }} offset={5}>
              <Descriptions
                column={1}
                layout="horizontal"
                size="small"
                bordered
                labelStyle={{color: '#000000'}}
              >
                {props.modelData.model_input_parameters.map((p, index) => {
                  if (index > startIndex) {
                    return (
                      <Descriptions.Item
                        label={`${p.name} [${p.unit}] (${p.lowerlimit} to ${p.upperlimit})`}
                      >
                        <InputNumber value={p.referencevalue?.toFixed(2)} />
                      </Descriptions.Item>
                    )
                  }
                })}
              </Descriptions>
            </Col>
          </Row>


          <Row>
            <Col style={{ paddingTop: '20px' }} offset={5}>
              <Descriptions
                column={1}
                layout="horizontal"
                size="small"
                bordered
                labelStyle={{color: '#000000'}}
              >
                <Descriptions.Item label="Name of Upstream Plant">
                  {props.modelData.interdependency_project}
                </Descriptions.Item>
                <Descriptions.Item label="Season">
                  {props.season ? props.season : 'Spring'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    powerOutput.interdependency_head_lowerlimit &&
                    powerOutput.interdependency_head_upperlimit
                      ? `Head of upstream Plant [ft](from ${powerOutput.interdependency_head_lowerlimit} to ${powerOutput.interdependency_head_upperlimit})`
                      : `Head of upstream Plant [ft](from - to -)`
                  }
                >
                  {powerOutput.interdependency_head_referencevalue?.toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    powerOutput.interdependency_pgen1_lowerlimit &&
                    powerOutput.interdependency_pgen1_upperlimit
                      ? `Power of upstream Plant [MW](from ${powerOutput.interdependency_pgen1_lowerlimit} to ${powerOutput.interdependency_pgen1_upperlimit})`
                      : `Power of upstream Plant [MW](from - to -)`
                  }
                >
                  {powerOutput.interdependency_pgen1_referencevalue?.toFixed(2)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {interdependencyMessage ? (
            <Alert message={interdependencyMessage.message} />
          ) : (
            <></>
          )}
          {unitData()}
        </>
      </div>
    </>
  )
}

export default ChildPlantTabAll
